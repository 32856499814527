import { dayjs, http } from '@/common/utils';

export default {
  namespaced: true,
  state: {
    resultData: null,
    restDateList: [],
    curTime: 0
  },
  mutations: {
    setResultData(state, data) {
      state.resultData = {
        total: data.checkNum || 0,
        monthTotal: data.monthCheck || 0,
        continuous: data.maxContinuous || 0,
        issue: data.no || 0,
        value: data.rightNumber,
        resultTime: dayjs(data.date).valueOf()
      };
    },

    setRestDateList(state, data) {
      state.restDateList = data || [];
    },

    setCurTime(state, time = 0) {
      state.curTime = time;
    }
  },
  actions: {
    updateResultData(
      { state: { resultData }, commit },
      config = { loading: false }
    ) {
      return http('dateNumber/lottery', {}, config).then(res => {
        let resData = res.number || {};
        commit('setCurTime', res.time * 1000);
        if (!resData.rightNumber) return res;
        let data = {
          no: resData.no || resultData.issue,
          checkNum: resData.checkNum || resultData.total,
          monthCheck: resData.monthCheck || resultData.monthTotal,
          maxContinuous: resData.maxContinuous || resultData.continuous,
          rightNumber: resData.rightNumber || resultData.value,
          date: resData.date || resultData.resultTime
        };
        commit('setResultData', data);
        return res;
      });
    },

    getRestDateList({ commit }, config = {}) {
      return http('rest', {}, config).then(res => {
        commit('setRestDateList', res);
        return res;
      });
    }
  }
};
