<template>
  <div id="index" class="body">
    <keep-alive>
      <router-view v-wechat-title="$route.meta.title"></router-view>
    </keep-alive>
    <van-tabbar
      class="tabbar"
      v-model="active"
      active-color="#FF4222"
      inactive-color="#929292"
      route
    >
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <app-icon
            :name="`tabbar-home${props.active ? '_active' : ''}`"
            size="36"
          ></app-icon>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/masterList">
        <span>奖师榜</span>
        <template #icon="props">
          <app-icon
            :name="`tabbar-master${props.active ? '_active' : ''}`"
            size="36"
          ></app-icon>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/user">
        <span>个人中心</span>
        <template #icon="props">
          <app-icon
            :name="`tabbar-user${props.active ? '_active' : ''}`"
            size="36"
          ></app-icon>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0
    };
  }
};
</script>

<style lang="scss" scoped>
.body {
  padding-bottom: calc($tabBarHeight + constant(safe-area-inset-bottom));
  padding-bottom: calc($tabBarHeight + env(safe-area-inset-bottom));
}

.tabbar {
  z-index: 10;
}
</style>
