import router from '@/router';
import { http, vant, dayjs } from '@/common/utils';

const { Dialog } = vant;

export default {
  namespaced: true,
  state: {
    userInfo: {},
    couponData: {
      empty: false,
      message: ''
    },
    promoteData: null,
    distributionData: null,
    distributionApplyData: null,
    superVipData: null
  },
  mutations: {
    clearUser(state) {
      state.userInfo = {};
      state.promoteData = null;
      state.distributionData = null;
    },
    setUserInfo(state, data) {
      state.userInfo = {
        id: data.id,
        face: data.avatarUrl,
        name: data.nickName || '',
        phone: data.phone || '',
        // 会员订单数量(包含过期服务订单)
        serviceOrderNum: data.serviceNum || 0,
        // 会员时间
        serviceTime: data.serviceTime || '',
        // 余额
        money: Number(data.money) || 0,
        // 可提现
        price: Number(data.price) || 0,
        // 积分
        integral: Number(data.integral) || 0,
        // 锦囊数量
        orderNum: Number(data.codeNum) || 0,
        // 是否是分销商
        isSalesman: data.isSuperior,
        // 上级分销商ID，为0则是一级分销商
        parentId: data.parentId,
        // 上级分销商code，如果存在，在分享的时候需要加到链接里
        parentCode: data.parentCode
      };
    },
    setCouponData(state, data) {
      state.couponData = {
        // 是否领券
        empty: data.couponEmpty || false,
        // 领券成功文本
        message: data.msgNewUser || ''
      };
    },
    setPromoteData(state, data) {
      state.promoteData = {
        orderTotal: data.orderNum,
        // 销售额
        saleVolume: data.amount,
        // 佣金
        commission: data.rebate
      };
    },
    setDistributionData(state, data) {
      state.distributionData = {
        distributionNumber: data.num,
        orderTotal: data.orderNum,
        profit: data.amount
      };
    },
    setDistributionApplyData(state, data = null) {
      if (!data) return (state.distributionApplyData = null);
      state.distributionApplyData = {
        status: data.status,
        error: data.explain || ''
      };
    },
    setSuperVipData(state, data = null) {
      if (!data) return (state.superVipData = null);
      state.superVipData = {
        beginTime: dayjs(data.beginTime).valueOf(),
        endTime: dayjs(data.endTime).valueOf()
      };
    },
    updateUserMoney(state, data = 0) {
      state.userInfo.money = data;
    },
    updateUserPrice(state, price = 0) {
      state.userInfo.price = price;
    }
  },
  actions: {
    async login(
      { commit },
      data = {
        codeData: { code: '', parentCode: '', agentCode: '' },
        toRouteName: ''
      }
    ) {
      const loginStatus = parseInt(sessionStorage.getItem('loginStatus') || 0);
      try {
        let url = `login?code=${data.codeData.code}`;
        let userData = null;

        if (data.codeData.parentCode) {
          url += `&parentCode=${data.codeData.parentCode}`;
        } else if (data.codeData.agentCode) {
          url += `&agentCode=${data.codeData.agentCode}`;
        }

        url =
          data.toRouteName === 'index' &&
          localStorage.getItem('loginType') === 'snsapi_userinfo'
            ? `${url}&from=index`
            : url;

        userData = await http(url, {}, { dialog: false });
        // 登录成功将登录状态设为2
        sessionStorage.setItem('loginStatus', 2);
        sessionStorage.setItem(
          'parentCode',
          userData.parentCode || userData.agentCode || ''
        );
        localStorage.setItem(
          'token',
          userData.accessToken ? userData.accessToken.accessToken : ''
        );
        commit('setUserInfo', {
          ...(userData.user || {}),
          parentCode: userData.parentCode
        });
        commit('setCouponData', userData);
        return Promise.resolve();
      } catch (error) {
        if (!loginStatus) {
          // 从未登录过登录失败只需将登录状态设为1
          sessionStorage.setItem('loginStatus', 1);
        } else {
          await Dialog.confirm({
            message: `登录失败，是否重新授权登录\n失败原因: ${
              JSON.parse(error.message).message
            }`
          })
            .then(() => {
              // .catch(err => err)忽略跳转相同路由的报错
              router.push('/login').catch(err => err);
            })
            .catch(() => {
              // .catch(err => err)忽略跳转相同路由的报错
              router.push('/').catch(err => err);
            });
        }
      }
    }
  }
};
