import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn'); // use locale
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);

const Group = {
  /**
   * @function 当前相对时间
   * @name currentRelativeTime
   * @param {number} timestamp - 传入时间戳或者能被dayjs解析的时间字符串
   * @returns {string} 今天内发布的，显示类似 "3分钟前" 这样的时间语法(fromNow) 否则显示时间格式(MM-DD HH:mm)
   */
  currentRelativeTime(timestamp) {
    if (!timestamp) return '';
    const isToday = !dayjs(new Date()).isAfter(
      dayjs(timestamp).format('YYYY-MM-DD'),
      'day'
    );
    return isToday
      ? dayjs(timestamp).fromNow().replace(/ /g, '')
      : dayjs(timestamp).format('YYYY-MM-DD');
  },

  /**
   * @function 时间戳根据格式转字符串
   * @name dateFormat
   * @param {number|string} timestamp - 传入时间戳或者能被dayjs解析的时间字符串
   * @returns {string}
   */
  dateFormat(timestamp, format = 'ddd') {
    if (!timestamp) return '';
    let weekDay = dayjs(timestamp).format(format);
    return weekDay;
  }
};

for (let key in Group) {
  Vue.filter(key, Group[key]);
} // 挂在到全局过滤器上

export default dayjs;
