import { REQUEST_URL } from './config';
import util from './util';
import axios from 'axios';
import vant from './vant';
import router from '@/router';
import store from '@/store';

const { transformKey } = util;
const { Toast, Dialog } = vant;

axios.defaults.baseURL = REQUEST_URL;
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  // 每次发送请求之前判断是否存在token
  // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况，此处token一般是用户完成登录后储存到localstorage里的
  config => {
    const token = localStorage.getItem('token') || '';
    // config.data = transformKey(config.data);
    config.headers['X-Token'] = token;
    return config;
  },
  error => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  response => {
    let promise = null;
    // TODO 模拟报错
    // if (response.config.url === 'unlockLog/add') {
    //   response.data.code = 401;
    //   response.data.msg = '我出错了';
    // }
    if (response.status === 200 && response.data.code === 200) {
      promise = Promise.resolve(response);
    } else if (response.status === 200) {
      promise = Promise.reject(
        new Error(
          JSON.stringify({
            title: '响应失败',
            code: response.data.code,
            url: response.config.url,
            message: response.data.message
          })
        )
      );
    } else {
      promise = Promise.reject(
        new Error(
          JSON.stringify({
            title: '未知错误',
            url: response.config.url,
            message: response.data.message
          })
        )
      );
    }
    return promise;
  },
  error => {
    // 我们可以在这里对异常状态作统一处理
    let errorData = null;
    if (error.response) {
      errorData = {
        title: '响应异常',
        url: error.response.config.url,
        code: error.response.status,
        message:
          (error.response.data && error.response.data.message) ||
          error.response.statusText
      };
    } else {
      errorData = {
        title: error.request ? '请求异常' : '未知异常',
        message: error.message === 'Network Error' ? '网络异常' : error.message
      };
    }
    return Promise.reject(new Error(JSON.stringify(errorData)));
  }
);

// http请求
const http = async (
  url,
  data = {},
  config = { loading: true, method: 'post', dialog: true }
) => {
  if (config.loading !== false) Toast.loading({});
  try {
    let resolve = await axios({
      url,
      method: config.method || 'post',
      data
    });
    // console.log('http-resolve', resolve);
    resolve.data.data = transformKey(resolve.data.data, 1);
    return resolve.data.data;
  } catch (error) {
    // console.log('http-error', error);
    let errorData = JSON.parse(error.message);
    if (errorData.code === 405 && localStorage.getItem('token')) {
      console.log('我是老用户了', errorData);
      localStorage.removeItem('token');
      store.commit('user/clearUser');
    } else if (errorData.code === 406) {
      localStorage.removeItem('token');
      store.commit('user/clearUser');
      Dialog.confirm({
        message: '您的登录已过期，请重新授权登录'
      })
        .then(() => {
          router.push('/login');
        })
        .catch(() => {});
    } else if (config.dialog !== false) {
      Dialog.alert({
        title: `${errorData.title}${
          errorData.code ? `-${errorData.code}` : ''
        }`,
        message: `${errorData.url ? `API: ${errorData.url}\n` : ''}message: ${
          errorData.message ? errorData.message : '无'
        }`
      });
    }
    throw new Error(error.message);
  } finally {
    if (config.loading !== false) Toast.clear();
  }
};

export default http;
