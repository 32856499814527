import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './modules/user';
import result from './modules/result';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  modules: {
    user,
    result
  },
  // 使用createPersistedState防止页面刷新vuex数据丢失
  plugins: [
    createPersistedState({
      // 使用sessionStorage存储
      storage: window.sessionStorage,
      // 只保存userInfo数据
      paths: ['user.userInfo']
    })
  ]
});

export default store;
