<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        v-wechat-title="$route.meta.title"
      >
      </router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      v-wechat-title="$route.meta.title"
    ></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#app {
  padding: $app-header-height 0 0;
}
</style>
