import './common/scss/base.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vueWechatTitle from 'vue-wechat-title';
import { config, util, wx, http, dayjs } from 'utils';
import appIcon from 'components/appIcon';

Vue.config.productionTip = false;
Vue.use(vueWechatTitle);

Vue.component('appIcon', appIcon);
Vue.prototype.$config = config;
Vue.prototype.$util = util;
Vue.prototype.$wx = wx;
Vue.prototype.$http = http;
Vue.prototype.$dayjs = dayjs;

Vue.filter('getForecastText', util.getForecastText);
Vue.filter('getForecastNumberJudge', util.getForecastNumberJudge);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
