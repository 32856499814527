<template>
  <svg class="module-appIcon" aria-hidden="true" :style="iconStyle">
    <use :xlink:href="`#icon-${name}`"></use>
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    size: {
      type: [String, Number],
      default: 0
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconStyle() {
      let style = {};
      if (this.size) {
        // 750是设计图宽度
        style.fontSize = `${(this.size * (100 / 750)).toFixed(3)}vw`;
      }
      if (this.color) {
        // 750是设计图宽度
        style.color = this.color;
      }
      return style;
    }
  },
  methods: {}
};
</script>

<style scoped>
.module-appIcon {
  flex: 0 0 auto;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
