import Vue from 'vue';
import {
  Toast,
  Icon,
  ImagePreview,
  Dialog,
  Swipe,
  SwipeItem,
  Popup,
  RadioGroup,
  Radio,
  Picker,
  List,
  Sticky,
  Sidebar,
  SidebarItem,
  Tabs,
  Tab,
  Tabbar,
  TabbarItem,
  Overlay,
  Calendar,
  DatetimePicker,
  Checkbox,
  CheckboxGroup
} from 'vant';

Toast.setDefaultOptions({ duration: 2000, className: 'vant-toast' });
Toast.setDefaultOptions('loading', {
  message: '加载中',
  forbidClick: true,
  overlay: false,
  duration: 0
});

Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(ImagePreview);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Picker);
Vue.use(List);
Vue.use(Sticky);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Overlay);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

export default {
  Toast,
  Dialog
};
