import {
  APPID,
  REQUEST_URL,
  STATIC_URL,
  BASE_URL,
  WX_SHARE_URL
} from './config';
import util from './util';
import http from './http';
import vant from './vant';
import wx from './wechat';
import dayjs from './day.js';
import echarts from './echarts.js';

const config = { APPID, REQUEST_URL, STATIC_URL, BASE_URL, WX_SHARE_URL };

export { config, util, http, vant, wx, dayjs, echarts };
