const {
  VUE_APP_BASE_URL,
  VUE_APP_REQUEST_URL,
  VUE_APP_STATIC_URL,
  VUE_APP_WX_SHARE_URL
} = process.env;

const BASE_URL = VUE_APP_BASE_URL;
const REQUEST_URL = VUE_APP_REQUEST_URL;
const STATIC_URL = VUE_APP_STATIC_URL;
const WX_SHARE_URL = VUE_APP_WX_SHARE_URL || '';
const APPID = 'wx4fa284fecc960f7e';

export { APPID, REQUEST_URL, STATIC_URL, BASE_URL, WX_SHARE_URL };
